import "./style.scss"
import Tooltip from "../node_modules/bootstrap/js/src/tooltip";
//import { createApp } from 'vue'
//import form from './components/mainForm'

window.addEventListener('DOMContentLoaded', function () {
    const tooltip = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"], [data-bs-toggle="tooltip"]'))
    tooltip.map(function (tooltipEl) {
        return new Tooltip(tooltipEl)
    });
    //let app = createApp(form);
   // app.mount('#authentication-form')
   // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
   // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //    return new bootstrap.Tooltip(tooltipTriggerEl)
   // })
});